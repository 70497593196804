<template>
    <section id="ProjTrattiEmotivi">
        

        <div class="container">
        
        <!-- PAGE TITLE -->
            <page-title 
                title="Tratti Emotivi" 
                subtitle="Questo progetto universitario si propone di produrre un libro contenente alcuni testi corredati da una grafica interessante. Questo in particolare utilizza la calligrafia (coerente con le caratteristiche del capitolo) per accompagnare la lettura." 
            />
        <!-- --------------------- -->

        <!-- PHOTO SHOWREEL -->
            <Showreel
                title=""
                :imgs="[
                    //Find is a path and start automatically from imgs folder..
                    //if want find image in a folder inside imgs folder u need to specify which folder before img name
                    //(folder/name.jpg)
                    {
                        find: 'proj-tratti-emotivi/fbook01.jpg',
                        imgType: 'cover',
                        alt: 'tratti emotivi book photo', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-tratti-emotivi/fbook02.jpg',
                        imgType: 'cover',
                        alt: 'tratti emotivi book photo', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-tratti-emotivi/fbook03.jpg',
                        imgType: 'cover',
                        alt: 'tratti emotivi book photo', // <-- image alt text
                        text: ''
                    }
                ]"
            />
        <!-- --------------------- -->

        <!-- READ THE WEBSITE OR MAGAZINE -->
            <ReadThe
                title="A Short Look Inside"
                :website="false"
                webUrl=""

                :magazine="true"
                :pages="pages /** Send an Array of pages images (all at same resolution)*/" 
                :startPage="null /** Insert start-page must be 'null' or number >= 1 */"
                :singlePage="false /** single-page format */"
                :haveCover="true /** Set true if your book have a cover */"
            />
        <!-- ---------------------------- -->

        <p class="focus-group">Aris Goi, Joaquín López, Elisabetta Luisotto, Salvatore Ferramosca.</p>

        </div>
    </section>
</template>

<script>
import PageTitle from '../../components/small/PageTitle.vue'
// import Gallery from '../../components/works-project/Gallery.vue'
import Showreel from '../../components/works-project/Showreel.vue'
// import TextAndImg from '../../components/works-project/TextAndImg.vue'
import ReadThe from '../../components/works-project/ReadThe.vue'

export default {
    name: 'ProjTrattiEmotivi',
    components: {
        PageTitle,
        Showreel,
        // TextAndImg,
        // Gallery,
        ReadThe,
    },
    metaInfo: {
        title: 'Tratti Emotivi',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: 'Questo progetto universitario si propone di produrre un libro contenente alcuni testi corredati da una grafica interessante. Questo in particolare utilizza la calligrafia (coerente con le caratteristiche del capitolo) per accompagnare la lettura.',
        }],
    },
    data(){
        return{
            pages: [ //use the link to imgs -- put the files in src/book/folder and will be see when website is uploaded
                "https://arisgoi.it/books/book-tratti-emotivi/book.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book2.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book3.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book4.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book5.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book6.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book7.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book14.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book15.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book22.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book23.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book24.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book25.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book36.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book37.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book50.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book51.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book54.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book55.jpg",
                "https://arisgoi.it/books/book-tratti-emotivi/book64.jpg",
            ],
        }
    },
    methods: {
        //
    }
}
</script>


<style scoped lang="scss">
@import '@/scss/_variables.scss';

</style>