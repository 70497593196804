var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"ProjTrattiEmotivi"}},[_c('div',{staticClass:"container"},[_c('page-title',{attrs:{"title":"Tratti Emotivi","subtitle":"Questo progetto universitario si propone di produrre un libro contenente alcuni testi corredati da una grafica interessante. Questo in particolare utilizza la calligrafia (coerente con le caratteristiche del capitolo) per accompagnare la lettura."}}),_c('Showreel',{attrs:{"title":"","imgs":[
                //Find is a path and start automatically from imgs folder..
                //if want find image in a folder inside imgs folder u need to specify which folder before img name
                //(folder/name.jpg)
                {
                    find: 'proj-tratti-emotivi/fbook01.jpg',
                    imgType: 'cover',
                    alt: 'tratti emotivi book photo', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-tratti-emotivi/fbook02.jpg',
                    imgType: 'cover',
                    alt: 'tratti emotivi book photo', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-tratti-emotivi/fbook03.jpg',
                    imgType: 'cover',
                    alt: 'tratti emotivi book photo', // <-- image alt text
                    text: ''
                }
            ]}}),_c('ReadThe',{attrs:{"title":"A Short Look Inside","website":false,"webUrl":"","magazine":true,"pages":_vm.pages /** Send an Array of pages images (all at same resolution)*/,"startPage":null /** Insert start-page must be 'null' or number >= 1 */,"singlePage":false /** single-page format */,"haveCover":true /** Set true if your book have a cover */}}),_c('p',{staticClass:"focus-group"},[_vm._v("Aris Goi, Joaquín López, Elisabetta Luisotto, Salvatore Ferramosca.")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }